import { useApi } from "@/composables/useApi";
import { Bundle, Product } from "@/types/typeDefinitions";
import { AxiosResponse } from "axios";

export function useProductAPI() {
    const { api } = useApi()

    const createProduct = async (product: Product): Promise<AxiosResponse | undefined> => {
        return await api.post('api/admin/product/create', product)
            .catch(() => {
                return undefined
            })
    }

    const getProduct = async (productID: string): Promise<Product> => {
        const response = await api.get('api/admin/product/' + productID)

        return response.data
    }

    const editProduct = async (product: Product): Promise<AxiosResponse> => {
        return api.post('api/admin/product/updateProduct/' + product.id, product)
    }

    const getProductList = async (): Promise<Product[]> => {
        const response = await api.get('api/admin/product/voucherProducts')

        return response.data
    }

    const deleteProduct = async (productId: string): Promise<AxiosResponse | undefined> => {
        return await api.delete('api/admin/product/' + productId)
            .catch(() => {
                return undefined
            })
    }

    // TODO: match with live productId's
    const getBundleForProductId = (productId: string): Bundle | undefined => {
        switch (productId) {
            case 'com.classplash.harmonycity':
                return Bundle.harmonycity
            case 'com.classplash.flutemaster':
                return Bundle.flutemaster
            case 'com.classplash.rhythmicvillage':
                return Bundle.rhythmicvillage
            case 'com.classplash.worldofmusic':
                return Bundle.worldofmusic
            case 'com.classplash.themagicflute':
                return Bundle.themagicflute

            default:
                return undefined
        }
    }

    return {
        createProduct,
        getProduct,
        editProduct,
        getProductList,
        deleteProduct,
        getBundleForProductId
    }
}
