












import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
    name: "LicenseLoginData",
    props: {
        appKey: {
            type: Object as PropType<{ id: string; key: string; }>,
            required: true
        }
    }
})
