import { useApi } from "@/composables/useApi";
import { License } from "@/types/typeDefinitions";
import { AxiosResponse } from "axios";

export function useLicense() {
    const { api } = useApi()

    const getLicenses = async (): Promise<License[]> => {
        const response = await api.get('api/user/getAllLicenses')

        return response.data
    }

    const sendSubscriptionLinkRequest = async (licenseId: string): Promise<AxiosResponse | undefined> => {
        return await api.get('/api/recharge/customerAccountNotification/' + licenseId)
            .catch(() => {
                return undefined
            })
    }

    return {
        getLicenses,
        sendSubscriptionLinkRequest
    }
}