































































































import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { useGlobalStorage } from "@/composables/globalStorage";
import { Bundle } from '@/types/typeDefinitions';
import LicenseLoginData from "@/components/LicenseLoginData.vue";
import { useLicense } from "@/api_connectors/license";
import { useProductAPI } from "@/api_connectors/product";

export default defineComponent({
    name: "AppSteps",
    components: { LicenseLoginData },
    setup() {
        const { globalStore } = useGlobalStorage()
        const { getLicenses } = useLicense()
        const { getBundleForProductId } = useProductAPI()

        const bundle = ref('')
        const appKey = reactive({ id: '', key: '' })

        const getAppKey = async () => {
            const licenses = await getLicenses()
            if (!licenses) return

            const license = licenses.find(license => getBundleForProductId(license.productId)?.toString() === bundle.value)
            if (!license?.appKey) return Object.assign(appKey, {});

            Object.assign(appKey, license.appKey)
        }

        onMounted(() => {
            bundle.value = globalStore.bundle
            getAppKey()
        })

        return {
            bundle,
            Bundle,
            appKey
        }
    }
})
