import { reactive, watch } from "@vue/composition-api";

const globalStore = reactive({
    // machineId: '',
    // componentId: '',
    JWT_token: '',
    apiConfig: {
        headers: {
            Authorization: 'Bearer'
        }
    },
    bundle: ''
})

watch(globalStore, () => { // TODO: delete, just for dev
    console.log('GLOBAL STORE: ', globalStore)
})


export function useGlobalStorage() {

    // initGlobalStore();
    return {
        globalStore,
    }
}